.home {
    max-width: 100%;
    text-align: center;
}

.home-image-container{
    width: 60vw;
    text-align: center;
    margin:auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.home-image-container img{
    width: 50vw;
}

.home button{
    font-family: 'Poppins', sans-serif;
    width: 20vw;
    border-radius: 5px;
    font-size: 18pt;
}

@media (max-width: 1000px) {
    
}
